<template>
    <div @click="changeSort($event)">
        <div 
            class="flex items-center"
            :class="[active && 'blue_color', params.column.colDef.sortable && 'cursor-pointer']">
            {{ params.displayName }}
            <template v-if="active">
                <i 
                    class="ml-2 fi"
                    :class="`fi-rr-angle-${sortDirection === 'asc' ? 'down' : 'up'}`"></i>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            sortDirection: null,
            active: null,
        }
    },
    created() {
        this.getCurrentSort()
    },
    methods: {
        getCurrentSort() {
            let sortedColumn = this.params.ordering || null
            if(this.params.ordering?.[0] === '-') {
                sortedColumn = sortedColumn.slice(1)
                this.sortDirection = 'desc'
            } else if(this.params.ordering) {
                this.sortDirection = 'asc'
            } else {
                this.sortDirection = null
            }
            if(this.params.column.colId === sortedColumn) {
                this.active = true
            }
        },
        changeSort(event) {
            this.params.ordering
           
            let newSortDirection
            if(!this.active) {
                newSortDirection = 'asc'
            } else {
                if(this.sortDirection === 'asc') {
                    newSortDirection = 'desc'
                } else if(this.sortDirection === 'desc') {
                    newSortDirection = null
                } else {
                    newSortDirection = 'asc'
                }
            }

            this.params.changeSort(this.params.column.colId, newSortDirection)
        },
    }
}
</script>

<style>

</style>